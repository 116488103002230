import { Button, Card } from "antd";
import { useState } from "react";
import SystemLogs from "./SystemLogs";
import Activity from "./Activity";
import Overview from "./Overview";
import { Row, Col } from "react-bootstrap";
import { CloseCircleFilled } from "@ant-design/icons";

const tabList = [
  {
    key: "overview",
    tab: "Overview",
  },
  {
    key: "activity",
    tab: "Avtivity",
  },
  {
    key: "systemLogs",
    tab: "System Logs",
  },
];

const contentList = {
  overview: <Overview />,
  activity: <Activity />,
  systemLogs: <SystemLogs />,
};
const tabList2 = [
  {
    key: "tab1",
    tab: "tab1",
  },
  {
    key: "tab2",
    tab: "tab2",
  },
];
const contentList2 = {
  tab1: <p>content1</p>,
  tab2: <p>content2</p>,
};
const Dashboard = () => {
  const [activeTabKey, setActiveTabKey] = useState("overview");
  const [activeTabKey2, setActiveTabKey2] = useState("tab1");

  const onTabChange = (key) => {
    setActiveTabKey(key);
  };
  const onTab2Change = (key) => {
    setActiveTabKey2(key);
  };
  const [showSplitScreen, setShowSplitScreen] = useState(false);

  return (
    <div className="dashboard mt-4">
      <Row>
        <Col md={showSplitScreen ? 7 : 12}>
          <Card
            style={
              {
                // width: "100%",
              }
            }
            title="Dashboard"
            extra={
              <Button onClick={() => setShowSplitScreen(true)}>More</Button>
            }
            tabList={tabList}
            activeTabKey={activeTabKey}
            onTabChange={onTabChange}
          >
            {contentList[activeTabKey]}
          </Card>
        </Col>

        {showSplitScreen && (
          <Col md={5}>
            <Card
              title="Split screen"
              extra={
                <Button
                  className="d-flex justify-content-center align-items-center"
                  shape="circle"
                  onClick={() => setShowSplitScreen(false)}
                  icon={<CloseCircleFilled />}
                ></Button>
              }
              tabList={tabList2}
              activeTabKey={activeTabKey2}
              onTabChange={onTab2Change}
            >
              {contentList2[activeTabKey2]}
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
};
export default Dashboard;
